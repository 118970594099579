/* default style */
.wrapper {
  font-size: 16px;
  width: 155px;
  height: 35px;
}

.input {
  font-size: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
  border: 1px solid #888;
  border-radius: 10px;
  outline: 0;
  padding: 3px 7px;
  display: block;
}

.text {
  font-size: 100%;
  width: 100%;
  height: 100%;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
