body {
  background-color: rgb(234, 234, 234) !important;
}

.redHoverBtn:hover {
  background-color: lightcoral !important;
}

.blueHoverBtn:hover {
  background-color: lightblue !important;
}

.greenHoverBtn:hover {
  background-color: lightgreen !important;
}

/*timepicker style*/
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding-left: 0;
  padding-right: 0;
  width: 100px;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

/*home page styles*/

.masthead {
  background-image: -webkit-linear-gradient(
    315deg,
    #4d7198 1%,
    #4d7198 20%,
    #90a4ae 70%,
    #90a4ae 99%
  ) !important;
  background-image: linear-gradient(
    135deg,
    #4d7198 1%,
    #4d7198 20%,
    #90a4ae 70%,
    #90a4ae 99%
  ) !important;
}
.masthead.segment {
  min-height: 100vh;
  padding: 1em 0 !important;
}
.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}
.masthead h1.ui.header {
  margin-top: 4.5em;
  margin-bottom: 0;
  font-size: 4em;
  font-weight: normal;
}
.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}
.footer.segment {
  padding: 5em 0;
}
.secondary.inverted.pointing.menu {
  border: none;
}
.loginBtn {
  width: 100%;
  color: white !important;
  background-color: #21ba45 !important;
}
.roadmapBtn {
  background: none !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  cursor: pointer !important;
}
.roadmapList {
  margin-left: 50px !important;
}
.landingPageSubHeading {
  margin-top: 50px !important;
  font-size: 30px !important;
}
.landingPageCallToAction {
  width: 100% !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
}
.landingPageAccountAccessButtons {
  margin-top: 20px !important;
  margin-left: 70px !important;
}
.landingPageLogo {
  font-size: 100px !important;
}
.landingPageVersion {
  width: 17% !important;
  margin-left: 150px !important;
  text-align: center !important;
}
.landingPageContentGrid {
  margin-top: 10px !important;
}
.landingPageContentGridRow {
  padding: 10px !important;
}
/* end home page styles */
/* navbar styles */
.fixSignUp {
  margin-left: 15px !important;
  margin-right: 0px !important;
}
.ui.menu .item img.logo {
  margin-right: 1.5em;
}
.ui.fixed.menu {
  background-image: -webkit-linear-gradient(
    315deg,
    #34515e 1%,
    #607d86 20%,
    #607d86 70%,
    #8eacbb 99%
  ) !important;
  background-image: linear-gradient(
    135deg,
    #34515e 1%,
    #607d86 20%,
    #607d86 70%,
    #8eacbb 99%
  ) !important;
}
.ui.main.container,
.main.segment {
  margin-top: 7em;
}
.ui.center.aligned.segment.attendance-preview {
  background-color: #f5f5f5;
}
.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}
.ui.menu .item > img:not(.ui) {
  margin-right: 1.5em !important;
}
.ui.menu:not(.vertical) .item > .button {
  margin-left: 0.5em;
}
/* Profile Page */
.improveMyScoreBtn {
  width: 100%;
  height: 100%;
}
.gridColCentered {
  text-align: center !important;
}
.imgCentered {
  margin: 0 auto;
}
.imgLeft {
  float: left !important;
}
.headerItemIcon {
  float: right;
}
.headerItemHeader {
  margin: 0;
}
.profilePageItemHeader {
  margin-bottom: 5px !important;
}
.profilePageItemHeaderVal {
  margin-top: 5px !important;
  color: green !important;
}
.profilePageItemHeaderValNegative {
  margin-top: 5px !important;
  color: red!important;
}
.profilePageItemHeaderGoalText {
  float: right !important;
  margin-top: -20px !important;
}
.profilePageItemHeaderTopText {
  float: right !important;
  margin-top: 5px !important;
}
.profileAtlasScore {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}
.profileTopHeader {
  margin-top: 20px !important;
  margin-left: 150px !important;
}
.profileHeaderSection {
  padding-bottom: 0px !important;
  padding-top: 3px !important;
}
.profilePageNetWorthCol {
  padding-top: 0 !important;
}
.profilePageNetWorthRow {
  padding-top: 0 !important;
}
.profilePageNetWorth {
  font-size: 40px !important;
  color: green !important;
}
.profilePageNetWorthText {
  font-size: 15px !important;
  color: grey !important;
  margin-left: 5px !important;
  margin-top: 15px !important;
}
.profilePageNetWorthHeader {
  margin-top: 0 !important;
  margin-bottom: 10px !important;
  float: left !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
/* End Profile Page */
/* Budget Page */
.budgetListHeader {
  position: absolute !important;
  font-weight: bold !important;
}
.greenBudgetBox {
  margin-right: 5px;
  display: inline-block;

  background-color: lightgreen;
  width: 8px;
  height: 8px;
  border-radius: 10px;
  margin-bottom: 1px;
}
.yellowBudgetBox {
  margin-right: 5px;
  display: inline-block;
  background-color: lightgoldenrodyellow;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-bottom: 1px;
}
.redBudgetBox {
  margin-right: 5px;
  display: inline-block;
  background-color: lightcoral;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-bottom: 1px;
}
.editableBudgetLabel {
  width: 125px !important;
  height: 16px !important;
}
.editableBudgetInput {
  height: 25px !important;
  background-color: white;
  border: none !important;
}
.editableBudgetText {
  font-size: 15px !important;
  padding: 0 !important;
}
.editableBudgetLabelInvert {
  width: 50px !important;
  height: 16px !important;
}
.editableBudgetInputInvert {
  height: 25px !important;
  background-color: white;
  border: none !important;
}
.editableBudgetTextInvert {
  font-size: 15px !important;
  padding-right: 30px !important;
  float: right;
  width: 20px !important;
}
.budgetAddBtn {
  background-color: rgba(0, 0, 0, 0.01) !important;
}
.budgetAddBtn:hover {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.budgetExtraHeading {
  padding-top: 0 !important;

  padding-bottom: 0 !important;
  margin-top: 10px;
  margin-bottom: 25px;
}
.budgetExtraHeadingHeader {
  margin-bottom: 15px;
  text-align: center;
}

.budgetExtraHeadingContent {
  color: green;
  text-align: center;
  margin-top: 0;
}
.budgetAddItemModalBtn {
  width:  100% !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  margin: 0 auto !important;
  background-color: #8eabbbc4 !important;
  margin-top: 25px !important;
}
.ui.icon.input {
  border: none !important;
  outline: none !important;
}
.budgetAddModalItemHolder {
  height: 300px !important;
  overflow-y: auto !important;
}
.budgetAddModalItem {
  float: left !important;
  width: 20% !important;
  text-align: center !important;
  margin-top: 12px !important;

  margin-bottom: 12px !important;
}
.budgetAddModalFooterItem {
  float: left !important;
  width: 50% !important;
}
.budgetAddModalFooterItem2 {
  float: left !important;
  width: 100% !important;
}
.budgetAddModalAddBtn {
  width: 100% !important;
}
.budgetAddItemBtn {
  width: 25% !important;
  margin-top: 0 !important;
}
.budgetAddModalIcon {
  margin-left: 15px !important;
  margin-right: 15px !important;
  margin-bottom: 10px !important;
  width: 65px !important;
  height: 65px !important;
}

.budgetAddModalItemBtn {
  box-shadow: none !important;
}
.budgetAddModalItemBtn:hover {
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.35) inset,
    0 0 0 0 rgba(34, 36, 38, 0.15) inset !important;
}
.budgetAtlasScoreProgress {
  margin: 0 !important;
  width: 225px;
}
.budgetAtlasScoreProgressHeader {
  margin-top: 3px !important;
  width: 225px;
}
.budgetIndicator {
  margin-left: 1px;
  margin-top: 2px;
  font-size: 10px;
  border-style: solid;
  color: white;
  border-width: 1px 1px 1px 1px;
  width: 15px;
  height: 15px;
  text-align: center; 
  line-height: 15px;
  border-radius: 50px;
  line-height: 12px;
}
.budgetIndicator:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.variableIndicator {
  background-color: #cce2ff;
}
.savingsIndicator {
  background-color: lightgreen;
}
.debtIndicator {
  background-color: palevioletred;
}
.preTaxIndicator {
  background-color: orange;
}
.variableAllocationsItemTitle {
  width: 20%;
  float: left;
}
.variableAllocationsItemProgress {
  width: 80%;
  float: left;
  text-align: center;
}
.variableAllocationsProgressItem {
  float: left;
  width: 10%;
}
.variableAllocationsProgress {
  float: left;
  width: 80%;
  margin-top: 4px !important;
}
.editIncomeDataHeader {
  float: left !important;
  margin-bottom: 0 !important;
  margin-top: 2px !important;
  margin-right: 10px !important;
}
.editIncomeAddBtn {
  width: 25px !important;
  height: 25px !important;
  float: left !important;
}
/* Start Savings Page */
.savingsItemContainer {
  width: 48% !important;
  float: left !important;
  padding-top: 15px !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
  padding-bottom: 0 !important;
  margin: 10px !important;
}
.savingsItemBtnContainer {
  padding-bottom: 20px !important;
  padding-top: 3px !important;
}
.savingsItemBtn {
  float: right;
}
.savingsCurrency {
  color: green;
  margin: 0;
}
.savingsInnerNodeCurrency {
  text-align: center;
}
.savingsItemHeader {
  margin-bottom: 15px !important;
  margin-top: 5px !important;
  line-height: 14px !important;
}
.addNewSavingsItemBtn {
  margin: 15px auto !important;
  display: block !important;
}
.savingsItemSubSectionRow {
  padding: 5px !important;
}
.savingsItemSubSection {
  padding: 1px !important; 
}
/* End Savings Page */
/* End Budget Page */
/* StepZilla */
button#next-button {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 21px;
  background-color: #21ba45;
  border: none;
  float: right;
  height: 35px;
  width: 70px;
  border-radius: 10px;
}
button#prev-button {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 21px;
  background-color: #21ba45;
  border: none;
  float: left;
  height: 35px;
  width: 70px;
  border-radius: 10px;
}

ol.progtrckr {
  list-style-type: none;
  padding: 0;
  margin-top: 0;
}
ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}
ol.progtrckr li span {
  padding: 0 1.5rem;
}
@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}
@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}
ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
  width: 15%;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #cccccc;
  width: 15%;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #5cb85c;
  width: 15%;
}

ol.progtrckr li:after {
  content: "\A0\A0";
}
ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}
ol.progtrckr li.progtrckr-todo:before {
  content: "\39F";
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}
ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #cccccc;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}
ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}
ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #5cb85c;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}
ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}
/* End Stepzilla */
/* Register Form Component */
.registerFormContent {
  margin-top: 10px !important;
}

.registerFormTopContent {
  padding-top: 0px !important;
}

.registerFormImage {
  height: 250px !important;
  margin-bottom: 10px;
}
.registerFormForm {
  padding: 21px;
}
.modalLayout {
  height: 90vh !important;
  width: 100% !important;
  padding: 0 !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  text-align: center;
}
.registerFormIntroContentImg {
  padding: 15px;
}
.registerFormBtn {
  color: white !important;
  background-color: #21ba45 !important;
}
.registerFormBudgetField {
  margin-left: 10px !important;
}
.registerFormBudgetGrid {
  padding: 21px !important;
  width: 100% !important;
}
.netIncomeCalculatorBtn {
  float: left !important;
  height: 10px !important;
  line-height: 0px !important;
}
.registerSectionDescriptionText {
  margin-top: 2em;
  margin-left: 2em;
  margin-right: 2em;
  margin-bottom: 1em;
}
.registerOverviewDivider {
  margin-left: 100px !important;
  margin-right: 100px !important;
}
.registerItem {
  margin-left: 100px !important;
  margin-right: 100px !important;
  text-align: left;
}
/* End Register Form Component */
/* GENERAL */
.standardSeparation {
  margin-top: 10px;
}
.fillToParent {
  height: 100%;
}

.vkColor {
  color: #4d7198 !important;
}
.vkColorBkg {
  background-color: #4d7198 !important;
}

.mainTheme {
  color: #607d8b !important;
}

.mainBkgTheme {
  background-color: #607d8b !important;
}

.secondaryTheme {
  color: #8eabbbc4 !important;
}

.secondaryBkgTheme {
  background-color: #8eabbbc4 !important;
}

.complimentTheme {
  color: #34515e !important;
}

.complimentBkgTheme {
  background-color: #34515e !important;
}

.contrastTheme {
  color: whitesmoke !important;
}

.rounded {
  border-radius: 5px;
}

.hidden {
  display: none;
}

.hoverBtn:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/* default style */
.wrapper {
  font-size: 16px;
  width: 155px;
  height: 35px;
}

.input {
  font-size: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
  border: 1px solid #888;
  border-radius: 10px;
  outline: 0;
  padding: 3px 7px;
  display: block;
}

.text {
  font-size: 100%;
  width: 100%;
  height: 100%;
  padding: 4px 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  white-space: nowrap;
}

